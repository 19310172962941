import { beApi, feApi } from '@/app/api/utils/api';
import { ApiResponse } from './fetch';

export async function getTokenFromFE(): Promise<string> {
  try {
    const res = (await feApi.url('/api').get('/token')) as ApiResponse<string>;

    return res?.data;
  } catch (error) {
    console.log(error);

    return '';
  }
}

type BeApiResponse = {
  data: {
    token: string;
    expiryDate: string;
  };
};

export async function getTokenFromBE(): Promise<string> {
  try {
    const res = (await beApi
      .options({
        next: {
          // 24 hours
          revalidate: 86400,
        },
      })
      .get('/v1/token/rand12')) as BeApiResponse;

    return res?.data?.token;
  } catch (error: any) {
    console.error(error);
    // const res = error?.response;

    return '';
  }
}
